<template>
   <main>
       <div class="pb">
            <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
        </div>

        <Form ref="form" :model="form" :rules="validate" label-position="top">
            <Divider orientation="left">基本信息</Divider>
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem label="类型" required>
                        <RadioGroup type="button" v-model="form.type">
                            <Radio label="string">字符串</Radio>
                            <Radio label="boolean">布尔值</Radio>
                            <Radio label="integer">整数</Radio>
                            <Radio label="double">小数</Radio>
                            <Radio label="time">时间</Radio>
                        </RadioGroup>
                    </FormItem>
                    
                    <FormItem label="KEY" prop="key" class="w75">
                        <Input :maxlength="30" show-word-limit v-model="form.key" />
                    </FormItem>

                    <FormItem label="默认值">
                        <i-switch v-model="form.value" v-if="form.type == 'boolean'" />
                        <InputNumber v-model="form.value" class="input-number-long" v-else-if="form.type == 'integer'"/>
                        <InputNumber v-model="form.value" class="input-number-long" :step="0.01" v-else-if="form.type == 'double'"/>
                        <TimePicker v-model="form.value" type="time" format="HH:mm" :steps="[0, 30]" v-else-if="form.type == 'time'"></TimePicker>
                        <Input v-model="form.value" v-else/>
                    </FormItem>

                    <FormItem label="名称" prop="label" class="w50">
                        <Input :maxlength="20" show-word-limit v-model="form.label" />
                    </FormItem>

                    <FormItem label="描述">
                        <Input :maxlength="60" show-word-limit v-model="form.desc" />
                    </FormItem>

                    <FormItem label="权重">
                        <InputNumber :min="-32768" :max="32767" class="input-number-long" v-model="form.weight" />
                    </FormItem>
                </Col>
            </Row>

            <Divider />
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem>
                        <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
                        <Button type="success" icon="md-filing"  @click="submit" v-else>保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
   </main>
</template>

<script>
    export default {
        data()
        {
            return {
                id  : this.$route.params.id,
                form: {
                    key   : '',
                    value : '',
                    type  : 'string',
                    label : '',
                    desc  : '',
                    weight: 0
                },
                validate: {
                    label: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                    key: [
                        { required: true, message: '请输入调用KEY', trigger: 'blur' }
                    ]
                }
            }
        },
        created()
        {
            this.id && this.loadData()
        },
        methods: {
            submit()
            {
                this.$refs.form.validate((valid) => {
                    if (valid)
                    {
                        const url = '/config/' + (this.id ? 'update/id/' + this.id : 'create')
                        this.$http.post(url, this.form)
                            .then(res => {
                                this.$router.back()
                            })
                    }
                })
            },
            loadData()
            {
                this.$http.get('/config/detail/id/' + this.id)
                    .then(res => {
                        this.form = res
                    })
            }
        },
        watch: {
            'form.type'(value)
            {
                switch (value)
                {
                    case 'boolean':
                        this.form.value = this.form.value ? true : false
                        break

                    case 'integer':
                    case 'double':
                        this.form.value = this.form.value ? this.form.value : 0
                        break

                    default:
                        this.form.value = this.form.value ? this.form.value : ''
                        break
                }
            }
        }
    }
</script>